import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  @ViewChild('navbarNav') navbarNav: ElementRef<HTMLDivElement>;

  constructor() {}

  public toggleNav(): void {
    if (this.navbarNav !== null) {
      this.navbarNav.nativeElement.classList.toggle('show');
    }
  }
}
