<nav class="navbar navbar-expand-sm navbar-light bg-light">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/home" (click)="toggleNav()">Home</a>
        <button class="navbar-toggler" type="button" (click)="toggleNav()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" #navbarNav>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/about" routerLinkActive="active" (click)="toggleNav()">About</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/cv" routerLinkActive="active" (click)="toggleNav()">CV</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/settings" routerLinkActive="active" (click)="toggleNav()">Settings</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
